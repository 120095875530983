import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import StructuredBreadcrumb from 'components/common/meta/StructuredBreadcrumb';
import {
  postParentCategory,
  postSlug,
  categorySlug,
  pageSlug,
  blogSlug,
  homeSlug,
} from "templates/Blog/utils/slugs";
import "./styles.sass";

const Breadcrumb = ({ links, hideLast }) => (
  <>
    <StructuredBreadcrumb items={links} />
    <div className="blog-breadcrumb">
      {links.map(({ to, name }, index) => (name != hideLast &&
        <Link to={to} className="blog-breadcrumb-link" key={`blog_breadcrumb_link_${index}`}>{name}</Link>
      ))}
    </div>
  </>
);

export const postBreadcrumbs = (post) =>
  categoryBreadcrumbs(postParentCategory(post)).concat({
    to: postSlug(post),
    name: post.title,
  });

export const authorBreadcrumbs = (author) =>
  blogBreadcrumbs().concat({
    to: author.uri,
    name: author.name,
  });

export const categoryBreadcrumbs = (category) =>
  blogBreadcrumbs().concat({
    to: categorySlug(category),
    name: category.name,
  });

export const blogBreadcrumbs = () => [
  {
    to: homeSlug(),
    name: "Accueil",
  },
  {
    to: blogSlug(),
    name: "Blog",
  },
];

let LinkType = PropTypes.shape({
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(LinkType),
  hideLast: PropTypes.string
};


export default Breadcrumb;
