import React from "react";
import "./styles.sass";
import PostCard from "./PostCard";
import { graphql } from "gatsby";

class PostList extends React.Component {
  render() {
    const { posts, showCategory } = this.props;
    return (
      <div className="blog-post-list-container">
        {posts.map((post, index) => (
          <div
            key={`blog_post_list_item_${index}`}
          >
            <PostCard showCategory={showCategory} post={post} />
          </div>
        ))}
      </div>
    );
  }
}

export const query = graphql`
  fragment BlogPostThumbnail on WpPost {
    author {
      node {
        firstName
        lastName
        name
        slug
        uri
      }
    }
    excerpt
    title
    uri
    categories {
      nodes {
        slug
        name
      }
    }
    id
    modified
    status
    featuredImage {
      node {
        ...BlogImageThumbnail
      }
    }
  }
`;

export default PostList;
