import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

import config from 'utils/siteConfig';

const StructuredBreadcrumb = ({
  items
}) => {
  const {
    siteUrl,
    siteName,
  } = config

  let index = 0
  let itemListElements = []

  items.forEach((row) => {
    index += 1

    itemListElements.push({
      "@type": "ListItem",
      "position": index,
      "name": row.name,
      "item": `${siteUrl}${row.to}`
    })
  })

  const schema = {
    "@context":"https://schema.org",
    "@type":"BreadcrumbList",
    "itemListElement": itemListElements
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    </>
  );
};

StructuredBreadcrumb.propTypes = {
  items: PropTypes.array.isRequired,
};

export default StructuredBreadcrumb;
