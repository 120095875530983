import React from "react";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  postSlug,
  postParentCategory,
  categorySlug,
} from "templates/Blog/utils/slugs";
import PostThumbnailImage from 'templates/Blog/common/PostThumbnailImage';

import "./styles.sass";

class PostThumbnail extends React.Component {
  render() {
    const { post } = this.props;
    const postLink = postSlug(post);
    const mainCategory = postParentCategory(post);
    return (
      <Row className="blog-post-thumbnail">
        <Col xs={12} md={5}>
          <div className="blog-post-thumbnail-image">
            <Link className="unstyled-link" to={postLink}>
              <PostThumbnailImage post={post} />
            </Link>
          </div>
        </Col>
        <Col xs={12} md={7}>
          <div className="blog-post-thumbnail-description">
            {mainCategory && (
              <Link className="unstyled-link dark-orange-link" to={categorySlug(mainCategory)}>{mainCategory.name}</Link>
            )}
            <Link className="unstyled-link blue-link" to={postLink}>
              <div className="blog-post-thumbnail-title truncate-text-2">
                {post.title}
              </div>
            </Link>
            <div
              className="blog-post-thumbnail-excerpt"
              dangerouslySetInnerHTML={{ __html: post.excerpt }}
            />
            <Link className="blog-post-thumbnil-cta" to={postLink}>
              Lire l'article
            </Link>
          </div>
        </Col>
      </Row>
    );
  }
}

export default PostThumbnail;
