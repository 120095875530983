// Very debatable implementation. Relies only on the fact that post only have one category so far
export const postParentCategory = (post) => post.categories.nodes[0];

export const postSlug = (post) => {
  return `/blog${post.uri}`
};

export const categorySlug = (category, omitSlash = false) => {
  const categorySlug = `/blog/${category.slug}`
  return omitSlash ? categorySlug : `${categorySlug}/`
}



// TEMP. Should be replaced without the page
export const pageSlug = (page) => `/blog/page/${page.slug}/`;

export const blogSlug = () => `/blog/`;

export const homeSlug = () => `/`;
