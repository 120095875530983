import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import { graphql } from "gatsby";
import BlogLayout from "components/common/blog/BlogLayout";
import Container from "react-bootstrap/Container";
import PostList from "templates/Blog/common/PostList";
import PostThumbnail from "templates/Blog/common/PostThumbnail";
import Blocks from "templates/Blog/Gutenberg/Blocks";
import Breadcrumb, { blogBreadcrumbs } from "templates/Blog/common/Breadcrumb";

class Home extends React.Component {
  render() {
    const {
      data: { page, recentPosts, featuredPosts },
    } = this.props;
    const recentPostsNodes = recentPosts.nodes;
    const latestArticle = recentPostsNodes[0];

    return (
      <BlogLayout showLinksInFooter={true}>
        <CharlesMeta
          title={page.title}
          description={page.title}
        />
        <Container>
          <Breadcrumb links={blogBreadcrumbs()} />
          <h1>Blog de Mia.co</h1>
          <h2>Dernier article</h2>
          <PostThumbnail post={latestArticle} />
          {featuredPosts.nodes.length > 0 &&
            <>
              <h2>Les plus lus</h2>
              <PostList showCategory={true} posts={featuredPosts.nodes} />
            </>
          }
          <h2>Les plus récents</h2>
          <PostList showCategory={true} posts={recentPostsNodes} />
          <Blocks context={page} blocks={page.blocks} />
        </Container>
      </BlogLayout>
    );
  }
}

export default Home;

export const pageQuery = graphql`
  query BlogHomeById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY")
      link
      slug
      status

      featuredImage {
        node {
          ...BlogImage
        }
      }
      blocks {
        ...GutenbergBlock
      }
    }
    featuredPosts: allWpPost(filter: {
      tags: { nodes: { elemMatch: { name: { eq: "home" } } } }
    }) {
      nodes {
        ...BlogPostThumbnail
      }
    }
    recentPosts: allWpPost(
      limit: 7,
      sort: { fields: date, order: DESC },
      filter: {categories: {nodes: {elemMatch: {name: {ne: "Newsletter"}}}}}
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
  }
`;
