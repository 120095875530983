import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class PostThumbnailImage extends React.Component {
  render() {
    const { post } = this.props;

    if (post.featuredImage && post.featuredImage.node)
      return <GatsbyImage image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData} className="img-fluid" alt={post.title} />

    console.error("Missing featured Image for article: ", post.title)
    return null
  }
}

export default PostThumbnailImage;
