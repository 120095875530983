import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  postSlug,
  postParentCategory,
  categorySlug,
} from "templates/Blog/utils/slugs";
import PostThumbnailImage from 'templates/Blog/common/PostThumbnailImage';

class PostCard extends React.Component {
  render() {
    const { post, showCategory } = this.props;
    const postLink = postSlug(post);
    const mainCategory = postParentCategory(post);
    return (
      <div className="blog-post-list-item">
        <div className="blog-post-list-item-image">
          <Link className="unstyled-link" to={postLink}>
            <PostThumbnailImage post={post} />
          </Link>
        </div>
        {showCategory && mainCategory && (
            <Link className="unstyled-link dark-orange-link" to={categorySlug(mainCategory)}>{mainCategory.name}</Link>
          )}
        <Link className="unstyled-link blue-link" to={postLink}>
          <div className="blog-post-list-item-title truncate-text-2">
            {post.title}
          </div>
        </Link>
        <Link className="unstyled-link" to={postLink}>
          <div
            className="blog-post-list-item-excerpt truncate-text-3"
            dangerouslySetInnerHTML={{ __html: post.excerpt }}
          />
        </Link>
      </div>
    );
  }
}

export default PostCard;
